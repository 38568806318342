import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';

const ChatbotUI = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);
  const initialMessageSent = useRef(false);
  const abortControllerRef = useRef(null);
    
  const correctPassword = '123'; // Replace with your desired password

  const handleLogin = (e) => {
    e.preventDefault();
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };
    
  const sendMessage = useCallback(async (message) => {
    if (isLoading) {
      console.log('A request is already in progress. Skipping this one.');
      return;
    }

    setIsLoading(true);
    setIsBotTyping(true);
    setMessages(prevMessages => [...prevMessages, { text: message, sender: 'user' }]);
    setInput('');

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    try {
      console.log('Sending message:', message);
      const response = await fetch('http://chat.newtech.studio:3001/api/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
        signal: abortControllerRef.current.signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedResponse = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        accumulatedResponse += chunk;
        
        const lines = accumulatedResponse.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const jsonStr = line.slice(6);
            try {
              const data = JSON.parse(jsonStr);
              console.log('Received data:', data);
              if (data.fullResponse) {
                setMessages(prevMessages => [
                  ...prevMessages,
                  { text: data.fullResponse, sender: 'bot', citations: data.citations }
                ]);
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
        accumulatedResponse = lines[lines.length - 1];
      }

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Request was aborted');
      } else {
        console.error('Error:', error);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: `Error: ${error.message}. Please try again later.`, sender: 'bot' }
        ]);
      }
    } finally {
      setIsLoading(false);
      setIsBotTyping(false);
      abortControllerRef.current = null;
    }
  }, [isLoading]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleSendMessage = () => {
    if (!isLoading && input.trim()) {
      sendMessage(input);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isLoading && input.trim()) {
      sendMessage(input);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !initialMessageSent.current) {
      sendMessage('Hallo, AI Aanbestedingsassistent!');
      initialMessageSent.current = true;
    }
  }, [isAuthenticated, sendMessage]);

  const TypingIndicator = () => {
    return React.createElement('div', {
      style: { 
        display: 'flex', 
        alignItems: 'center', 
        padding: '8px 12px',
        borderRadius: '15px',
        backgroundColor: '#f0f0f0',
        maxWidth: '60px',
        marginBottom: '10px'
      }
    }, 
    [1, 2, 3].map((i) => 
      React.createElement('div', {
        key: i,
        style: {
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          backgroundColor: '#888',
          margin: '0 2px',
          animation: `bounce 0.6s ${(i-1)*0.2}s infinite alternate`
        }
      })
    ));
  };

  if (!isAuthenticated) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <form onSubmit={handleLogin} style={{ textAlign: 'center' }}>
          <h2 style={{ marginBottom: '20px' }}>Enter Password</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ padding: '8px', marginRight: '10px' }}
          />
          <button type="submit" style={{
            padding: '8px 15px',
            backgroundColor: '#0066cc',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}>
            Login
          </button>
        </form>
      </div>
    );
  }

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      {React.createElement('style', null, `
        @keyframes bounce {
          0% { transform: translateY(0); }
          100% { transform: translateY(-5px); }
        }
      `)}
      <div style={{ marginBottom: '20px', textAlign: 'center' }}>
        <img src="./logo_AB.jpg" alt="Company Logo" style={{ maxWidth: '200px', height: 'auto' }} />
      </div>

      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{ width: '5%', paddingRight: '10px' }}>
          <p style={{ fontSize: '14px' }}></p>
        </div>

        <div style={{ width: '90%' }}>
          <h2 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '15px', textAlign: 'center' }}>Aanbestedingsassistent</h2>
          <div style={{ border: '1px solid #ccc', borderRadius: '5px', height: '500px', overflowY: 'auto', marginBottom: '15px', padding: '10px' }}>
            {messages.map((message, index) => (
              <div key={index} style={{ marginBottom: '10px', textAlign: message.sender === 'user' ? 'right' : 'left' }}>
                <span style={{
                  display: 'inline-block',
                  padding: '8px 12px',
                  borderRadius: '15px',
                  backgroundColor: message.sender === 'user' ? '#e6f2ff' : '#f0f0f0',
                  maxWidth: '80%'
                }}>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </span>
                {message.citations && message.citations.length > 0 && (
                  <div className="mt-2 text-sm text-gray-600">
                    <strong>Bronnen:</strong>
                    <ol>
                      {message.citations.map((citation, idx) => {
                        const displayUrl = citation.mappedUrl || citation.originalUrl;
                        return (
                          <li key={idx}>
                            [{citation.index}]{' '}
                            <a
                              href={displayUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 hover:underline"
                            >
                              {displayUrl}
                            </a>
                            {citation.error && ` (${citation.error})`}
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                )}
              </div>
            ))}
            {isBotTyping && React.createElement(TypingIndicator)}
          </div>
          <div style={{ display: 'flex' }}>
            <input
              type="text"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder="Stel een vraag..."
              style={{ flexGrow: 1, padding: '8px', borderRadius: '5px 0 0 5px', border: '1px solid #ccc' }}
              disabled={isLoading}
            />
            <button
              onClick={handleSendMessage}
              style={{
                padding: '8px 15px',
                backgroundColor: '#0066cc',
                color: 'white',
                border: 'none',
                borderRadius: '0 5px 5px 0',
                cursor: 'pointer'
              }}
              disabled={isLoading}
            >
              Send
            </button>
          </div>
        </div>

        <div style={{ width: '5%', paddingLeft: '10px' }}>
          <p style={{ fontSize: '14px' }}></p>
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        <img src="./logo-vandoorne.jpg" alt="Company Logo" style={{ maxWidth: '200px', height: 'auto' }}/>
        <p>
          Deze antwoorden zijn door AI gegenereerd op basis van onze kennisbank.  Om zeker te zijn, neem contact op met: {' '}
          <a href="mailto:Verberne@vandoorne.com" style={{ color: '#0066cc', textDecoration: 'none' }}>
            Gijs Verberne
          </a>
        </p>
      </div>
    </div>
  );
};

export default ChatbotUI;
